import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import LayoutMain from "../components/LayoutMain";
import TemplateContact from "../features/contact/components/TemplateContact";
import { Helmet } from "react-helmet";

const Contact = (props: PageProps) => {
  const { data }: any = props;
  if (!data) {
    return <>Erreur no data</>;
  }

  const logo: IGatsbyImageData | undefined = getImage(
    data.sanitySiteSettings.logo.asset
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact piscinisite - Energie Piscines</title>
        <link
          rel="canonical"
          href="https://energiepiscines.com/contact-pisciniste-vence/"
        />
        <meta
          name="description"
          content="Contacter votre pisciniste pour l'entretien, la construction ou la réparation de votre piscine."
        />
      </Helmet>
      <LayoutMain logo={logo}>
        <TemplateContact />
      </LayoutMain>
    </>
  );
};

export const query = graphql`
  {
    sanityHome(slug: { current: { eq: "home" } }) {
      title
      sectionServices {
        _key
        title
        _rawResume(resolveReferences: { maxDepth: 10 })
        slug {
          current
        }
        vignette {
          alt
          asset {
            gatsbyImageData(
              width: 380
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      mainImage {
        asset {
          gatsbyImageData(
            width: 1920
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    sanitySiteSettings {
      title
      logo {
        asset {
          gatsbyImageData(
            width: 267
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;

export default Contact;
