import SectionForm from "../../../components/SectionForm"
import GoogleMap from "../../../components/GoogleMap"


const TemplateContact = () => {
	return (
		<div className="contact">
			<SectionForm />
			<GoogleMap />
		</div>
	)
}

export default TemplateContact